var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};

// src/components/logto-avatar.ts
import { msg } from "@lit/localize";
import { LitElement, css } from "lit";
import { customElement, property } from "lit/decorators.js";

// src/utils/css.ts
import { unsafeCSS } from "lit";
var unit = (...values) => {
  if (values.length === 0 || values.length > 4) {
    throw new Error("unit() accepts 1 to 4 arguments");
  }
  if (values.some((value) => typeof value !== "number")) {
    throw new Error("unit() accepts only numbers");
  }
  return unsafeCSS(values.map((value) => `${value * 4}px`).join(" "));
};

// src/components/logto-avatar.ts
var tagName = "logto-avatar";
var sizes = Object.freeze({
  medium: unit(8),
  large: unit(10)
});
var LogtoAvatar = class extends LitElement {
  constructor() {
    super(...arguments);
    this.size = "medium";
    this.src = "";
    this.alt = msg("Avatar", {
      id: "account.profile.personal-info.avatar",
      desc: "The avatar of the user."
    });
  }
  connectedCallback() {
    super.connectedCallback();
    this.style.setProperty("width", sizes[this.size].cssText);
    this.style.setProperty("height", sizes[this.size].cssText);
    if (this.src) {
      this.style.setProperty("background-color", "#adaab422");
      this.style.setProperty("background-image", `url(${this.src})`);
    } else {
      this.style.setProperty("background-color", "#e74c3c");
    }
  }
};
LogtoAvatar.tagName = tagName;
LogtoAvatar.styles = css`
    :host {
      display: block;
      border-radius: ${unit(2)};
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  `;
__decorateClass([
  property({ reflect: true })
], LogtoAvatar.prototype, "size", 2);
__decorateClass([
  property({ reflect: true })
], LogtoAvatar.prototype, "src", 2);
__decorateClass([
  property({ reflect: true })
], LogtoAvatar.prototype, "alt", 2);
LogtoAvatar = __decorateClass([
  customElement(tagName)
], LogtoAvatar);

// src/components/logto-button.ts
import { LitElement as LitElement2, css as css3, html } from "lit";
import { customElement as customElement2, property as property2 } from "lit/decorators.js";

// src/utils/theme.ts
import { unsafeCSS as unsafeCSS2 } from "lit";

// src/utils/string.ts
var kebabCase = (value) => {
  return value.replaceAll(/([^A-Z])([A-Z])/g, "$1-$2").replaceAll(/([A-Z])([A-Z][^A-Z])/g, "$1-$2").toLowerCase();
};

// src/utils/theme.ts
var defaultFontFamily = "-apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji";
var defaultFont = Object.freeze({
  fontTitle1: `600 20px / 28px ${defaultFontFamily}`,
  fontTitle2: `600 16px / 24px ${defaultFontFamily}`,
  fontTitle3: `600 14px / 20px ${defaultFontFamily}`,
  fontLabel1: `500 16px / 24px ${defaultFontFamily}`,
  fontLabel2: `500 14px / 20px ${defaultFontFamily}`,
  fontLabel3: `500 12px / 16px ${defaultFontFamily}`,
  fontBody1: `400 16px / 24px ${defaultFontFamily}`,
  fontBody2: `400 14px / 20px ${defaultFontFamily}`,
  fontBody3: `400 12px / 16px ${defaultFontFamily}`,
  fontSectionHeading1: `700 12px / 16px ${defaultFontFamily}`,
  fontSectionHeading2: `700 10px / 16px ${defaultFontFamily}`
});
var defaultTheme = Object.freeze({
  ...defaultFont,
  colorPrimary: "#5d34f2",
  colorOnPrimary: "#000",
  colorPrimaryPressed: "#e6deff",
  colorPrimaryHover: "#af9eff",
  colorTextPrimary: "#191c1d",
  colorTextLink: "#5d34f2",
  colorTextSecondary: "#747778",
  colorBorder: "#c4c7c7",
  colorCardTitle: "#928f9a",
  colorLayer1: "#000",
  colorLayer2: "#2d3132",
  colorLineDivider: "#191c1d1f",
  colorDisabled: "#5c5f60",
  colorDisabledBackground: "#2d3132",
  colorHover: "#191c1d14",
  colorHoverVariant: "#5d34f214",
  colorPressed: "rgba(25, 28, 29, 12%)",
  colorFocused: "rgba(25, 28, 29, 16%)",
  colorFocusedVariant: "#5d34f229",
  colorOverlay: "#000000b3",
  colorPlaceholder: "#747778"
});
var darkTheme = Object.freeze({
  ...defaultFont,
  colorPrimary: "#7958ff",
  colorOnPrimary: "#fff",
  colorPrimaryPressed: "#5d34f2",
  colorPrimaryHover: "#947dff",
  colorTextPrimary: "#f7f8f8",
  colorTextLink: "#cabeff",
  colorTextSecondary: "#a9acac",
  colorBorder: "#5c5f60",
  colorCardTitle: "#928f9a",
  colorLayer1: "#2a2c32",
  colorLayer2: "#34353f",
  colorLineDivider: "#f7f8f824",
  colorDisabled: "#5c5f60",
  colorDisabledBackground: "#2d3132",
  colorHover: "#f7f8f814",
  colorHoverVariant: "#cabeff14",
  colorPressed: "rgba(247, 248, 248, 12%)",
  colorFocused: "rgba(247, 248, 248, 16%)",
  colorFocusedVariant: "#cabeff29",
  colorOverlay: "#0000003c",
  colorPlaceholder: "#747778"
});
var toLogtoCssEntries = (theme) => Object.entries(theme).map(
  ([key, value]) => Object.freeze([`--logto-${kebabCase(key)}`, value])
);
var toVar = (value) => unsafeCSS2(`var(--logto-${kebabCase(value)})`);
var vars = Object.freeze(
  Object.fromEntries(Object.keys(defaultTheme).map((key) => [key, toVar(key)]))
);
var toLitCss = (theme, name) => unsafeCSS2(
  `:host${typeof name === "string" ? `([theme=${name}])` : ""} {
` + toLogtoCssEntries(theme).map(([key, value]) => `${key}: ${value};`).join("\n") + "\n}"
);

// src/components/logto-button.styles.ts
import { css as css2 } from "lit";
var buttonSizes = css2`
  :host([size='small']) {
    height: 30px;
    padding: ${unit(0, 3)};
  }

  :host([size='small'][type='text']) {
    height: 24px;
  }

  :host([size='medium']) {
    height: 36px;
    padding: ${unit(0, 4)};
  }

  :host([size='medium'][type='text']) {
    height: 28px;
    font: ${vars.fontLabel1};
  }

  :host([size='large']) {
    height: 44px;
    padding: ${unit(0, 6)};
  }

  :host([size='large'][type='text']) {
    height: 28px;
    font: ${vars.fontLabel1};
  }
`;
var textButton = css2`
  :host([type='text']) {
    background: none;
    border-color: transparent;
    font: ${vars.fontLabel2};
    color: ${vars.colorTextLink};
    padding: ${unit(0.5, 1)};
    border-radius: ${unit(1)};
  }

  :host([type='text']:disabled) {
    color: ${vars.colorDisabled};
  }

  :host([type='text']:focus-visible) {
    outline: 2px solid ${vars.colorFocusedVariant};
  }

  :host([type='text']:not(:disabled):not(:active):hover) {
    background: ${vars.colorHoverVariant};
  }
`;
var defaultButton = css2`
  :host([type='default']) {
    background: ${vars.colorLayer1};
    color: ${vars.colorTextPrimary};
    border: 1px solid ${vars.colorBorder};
  }

  :host([type='default']:disabled) {
    color: ${vars.colorPlaceholder};
  }

  :host([type='default']:focus-visible) {
    outline: 3px solid ${vars.colorFocused};
  }

  :host([type='default']:active) {
    background: ${vars.colorPressed};
  }

  :host([type='default']:not(:disabled):not(:active):hover) {
    background: ${vars.colorHover};
  }
`;
var primaryButton = css2`
  :host([type='primary']) {
    background: ${vars.colorPrimary};
    color: ${vars.colorOnPrimary};
  }

  :host([type='primary']:disabled) {
    background: ${vars.colorDisabledBackground};
    color: ${vars.colorPlaceholder};
  }

  :host([type='primary']:focus-visible) {
    outline: 3px solid ${vars.colorFocusedVariant};
  }

  :host([type='primary']:active) {
    background: ${vars.colorPrimaryPressed};
  }

  :host([type='primary']:not(:disabled):not(:active):hover) {
    background: ${vars.colorPrimaryHover};
  }
`;

// src/components/logto-button.ts
var tagName2 = "logto-button";
var LogtoButton = class extends LitElement2 {
  constructor() {
    super(...arguments);
    this.type = "default";
    this.size = "medium";
  }
  connectedCallback() {
    super.connectedCallback();
    this.role = "button";
    this.tabIndex = 0;
  }
  render() {
    return html`<slot></slot>`;
  }
};
LogtoButton.tagName = tagName2;
LogtoButton.styles = [
  css3`
      :host {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        font: ${vars.fontLabel2};
        transition: background-color 0.2s ease-in-out;
        white-space: nowrap;
        user-select: none;
        position: relative;
        text-decoration: none;
        gap: ${unit(2)};
        border-radius: ${unit(2)};
        cursor: pointer;
      }

      :host(:disabled) {
        cursor: not-allowed;
      }
    `,
  buttonSizes,
  textButton,
  defaultButton,
  primaryButton
];
__decorateClass([
  property2({ reflect: true })
], LogtoButton.prototype, "type", 2);
__decorateClass([
  property2({ reflect: true })
], LogtoButton.prototype, "size", 2);
LogtoButton = __decorateClass([
  customElement2(tagName2)
], LogtoButton);

// src/components/logto-card-section.ts
import { localized } from "@lit/localize";
import { LitElement as LitElement3, css as css4, html as html2 } from "lit";
import { customElement as customElement3, property as property3 } from "lit/decorators.js";

// src/phrases/index.ts
import { msg as msg2 } from "@lit/localize";
var notSet = msg2("Not set", {
  id: "general.fallback-title",
  desc: "A fallback title when the title or heading of a component is not provided."
});

// src/components/logto-card-section.ts
var tagName3 = "logto-card-section";
var LogtoCardSection = class extends LitElement3 {
  constructor() {
    super(...arguments);
    this.heading = notSet;
  }
  render() {
    return html2`
      <header>${this.heading}</header>
      <slot></slot>
    `;
  }
};
LogtoCardSection.tagName = tagName3;
LogtoCardSection.styles = css4`
    header {
      font: ${vars.fontLabel2};
      color: ${vars.colorTextPrimary};
      margin-bottom: ${unit(1)};
    }
  `;
__decorateClass([
  property3()
], LogtoCardSection.prototype, "heading", 2);
LogtoCardSection = __decorateClass([
  customElement3(tagName3),
  localized()
], LogtoCardSection);

// src/components/logto-card.ts
import { LitElement as LitElement4, css as css5, html as html3 } from "lit";
import { customElement as customElement4 } from "lit/decorators.js";
var tagName4 = "logto-card";
var LogtoCard = class extends LitElement4 {
  render() {
    return html3`<slot></slot>`;
  }
};
LogtoCard.tagName = tagName4;
LogtoCard.styles = css5`
    :host {
      background: ${vars.colorLayer1};
      border-radius: ${unit(4)};
      padding: ${unit(6)};
    }
  `;
LogtoCard = __decorateClass([
  customElement4(tagName4)
], LogtoCard);

// src/components/logto-form-card.ts
import { localized as localized2 } from "@lit/localize";
import { cond } from "@silverhand/essentials";
import { LitElement as LitElement5, css as css6, html as html4 } from "lit";
import { customElement as customElement5, property as property4 } from "lit/decorators.js";
var tagName5 = "logto-form-card";
var LogtoFormCard = class extends LitElement5 {
  constructor() {
    super(...arguments);
    this.heading = notSet;
    this.description = "";
  }
  render() {
    return html4`
      <logto-card>
        <header>
          <div role="heading">${this.heading}</div>
          ${cond(this.description && html4`<p>${this.description}</p>`)}
        </header>
        <div class="spacer"></div>
        <slot></slot>
      </logto-card>
    `;
  }
};
LogtoFormCard.tagName = tagName5;
LogtoFormCard.styles = css6`
    logto-card {
      display: flex;
      padding: ${unit(6, 8)};
    }

    header {
      flex: 7;
      font: ${vars.fontSectionHeading1};
      color: ${vars.colorCardTitle};
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    div.spacer {
      flex: 1;
    }

    slot {
      display: block;
      flex: 16;
    }
  `;
__decorateClass([
  property4()
], LogtoFormCard.prototype, "heading", 2);
__decorateClass([
  property4()
], LogtoFormCard.prototype, "description", 2);
LogtoFormCard = __decorateClass([
  customElement5(tagName5),
  localized2()
], LogtoFormCard);

// src/components/logto-icon-button.ts
import { LitElement as LitElement6, html as html5, css as css7 } from "lit";
import { customElement as customElement6 } from "lit/decorators.js";
var tagName6 = "logto-icon-button";
var LogtoIconButton = class extends LitElement6 {
  connectedCallback() {
    super.connectedCallback();
    this.role = "button";
    this.tabIndex = 0;
  }
  render() {
    return html5`<slot></slot>`;
  }
};
LogtoIconButton.tagName = tagName6;
LogtoIconButton.styles = css7`
    :host {
      all: unset;
      border-radius: ${unit(1.5)};
      transition: background 0.2s ease-in-out;
      padding: ${unit(1)};
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    :host(:disabled) {
      cursor: not-allowed;
    }

    :host(:focus-visible) {
      background: ${vars.colorFocused};
    }

    :host(:not(:disabled):hover) {
      background: ${vars.colorHover};
    }

    ::slotted(svg) {
      color: ${vars.colorTextSecondary};
    }
  `;
LogtoIconButton = __decorateClass([
  customElement6(tagName6)
], LogtoIconButton);

// src/components/logto-list-row.ts
import { localized as localized3, msg as msg3 } from "@lit/localize";
import { LitElement as LitElement7, css as css8, html as html6 } from "lit";
import { customElement as customElement7 } from "lit/decorators.js";
var tagName7 = "logto-list-row";
var LogtoListRow = class extends LitElement7 {
  render() {
    return html6`
      <slot name="title">${msg3("Title", { id: "general.title" })}</slot>
      <slot name="content"><span class="not-set">${notSet}</span></slot>
      <slot name="actions">${msg3("Actions", { id: "general.actions" })}</slot>
    `;
  }
};
LogtoListRow.tagName = tagName7;
LogtoListRow.styles = css8`
    :host {
      box-sizing: border-box;
      display: grid;
      height: ${unit(16)};
      padding: ${unit(2, 6)};
      grid-template-columns: 1fr 2fr 1fr;
      align-items: center;
      color: ${vars.colorTextPrimary};
      font: ${vars.fontBody2};
    }

    :host(:not(:last-child)) {
      border-bottom: 1px solid ${vars.colorLineDivider};
    }

    slot {
      display: block;
    }

    slot[name='title'] {
      font: ${vars.fontLabel2};
    }

    slot[name='actions'] {
      text-align: right;
    }

    span.not-set {
      color: ${vars.colorTextSecondary};
    }
  `;
LogtoListRow = __decorateClass([
  customElement7(tagName7),
  localized3()
], LogtoListRow);

// src/components/logto-list.ts
import { LitElement as LitElement8, css as css9, html as html7 } from "lit";
import { customElement as customElement8 } from "lit/decorators.js";
var tagName8 = "logto-list";
var LogtoList = class extends LitElement8 {
  render() {
    return html7`<slot></slot>`;
  }
};
LogtoList.tagName = tagName8;
LogtoList.styles = css9`
    :host {
      display: block;
      border-radius: ${unit(2)};
      border: 1px solid ${vars.colorLineDivider};
    }
  `;
LogtoList = __decorateClass([
  customElement8(tagName8)
], LogtoList);

// src/components/logto-modal-layout.ts
import { consume } from "@lit/context";
import { localized as localized4, msg as msg4 } from "@lit/localize";
import { cond as cond2, noop as noop2 } from "@silverhand/essentials";
import { LitElement as LitElement9, html as html9, css as css10 } from "lit";
import { customElement as customElement9, property as property5 } from "lit/decorators.js";

// src/icons/close.svg
import { html as html8 } from "lit";
var close_default = html8`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z"
    fill="currentColor" />
</svg>
`;

// src/components/logto-modal.context.ts
import { createContext } from "@lit/context";
import { noop } from "@silverhand/essentials";
var ModalContext = createContext("modal-context");
var modalContext = {
  onClose: noop
};

// src/components/logto-modal-layout.ts
var tagName9 = "logto-modal-layout";
var LogtoModalLayout = class extends LitElement9 {
  constructor() {
    super(...arguments);
    this.heading = msg4("Not set", {
      id: "general.fallback-title",
      desc: "A fallback title when the title or heading of a component is not provided."
    });
  }
  render() {
    const { onClose } = this.context ?? {};
    return html9`
      <header>
        <h1>${this.heading}</h1>
        ${cond2(
      onClose && onClose !== noop2 && html9`<logto-icon-button @click=${onClose}>${close_default}</logto-icon-button>`
    )}
      </header>
      <slot></slot>
      <footer>
        <slot name="footer"></slot>
      </footer>
    `;
  }
};
LogtoModalLayout.tagName = tagName9;
LogtoModalLayout.styles = css10`
    header {
      font: ${vars.fontTitle1};
      color: ${vars.colorTextPrimary};
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: ${unit(6)};
    }

    h1 {
      all: unset;
    }

    footer:not(:empty) {
      margin-top: ${unit(6)};
      display: flex;
      justify-content: flex-end;
      gap: ${unit(4)};
      align-items: center;
    }
  `;
__decorateClass([
  property5({ reflect: true })
], LogtoModalLayout.prototype, "heading", 2);
__decorateClass([
  consume({ context: ModalContext })
], LogtoModalLayout.prototype, "context", 2);
LogtoModalLayout = __decorateClass([
  customElement9(tagName9),
  localized4()
], LogtoModalLayout);

// src/components/logto-modal.ts
import { provide } from "@lit/context";
import { noop as noop3 } from "@silverhand/essentials";
import { LitElement as LitElement10, html as html10, css as css11 } from "lit";
import { customElement as customElement10, property as property6 } from "lit/decorators.js";
import { createRef, ref } from "lit/directives/ref.js";
var tagName10 = "logto-modal";
var LogtoModal = class extends LitElement10 {
  constructor() {
    super(...arguments);
    this.open = false;
    this.onClose = noop3;
    this.context = modalContext;
    this.dialogRef = createRef();
  }
  render() {
    return html10`<dialog
      ${ref(this.dialogRef)}
      @keydown=${(event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        this.onClose();
      }
    }}
    >
      <slot></slot>
    </dialog> `;
  }
  toggleDialog() {
    if (this.open) {
      this.dialogRef.value?.showModal();
    } else {
      this.dialogRef.value?.close();
    }
  }
  handlePropertiesChange(changedProperties) {
    if (changedProperties.has("open")) {
      this.toggleDialog();
    }
    if (changedProperties.has("onClose")) {
      this.context.onClose = this.onClose;
    }
  }
  firstUpdated(changedProperties) {
    this.handlePropertiesChange(changedProperties);
  }
  updated(changedProperties) {
    this.handlePropertiesChange(changedProperties);
  }
};
LogtoModal.tagName = tagName10;
LogtoModal.styles = css11`
    dialog {
      padding: 0;
      border: none;
      color: ${vars.colorTextPrimary};
      background: ${vars.colorLayer1};
      border-radius: ${unit(4)};
      padding: ${unit(6)};
      width: 95%;
      max-width: 600px;
      min-width: 300px;
    }

    dialog::backdrop {
      background-color: ${vars.colorOverlay};
    }
  `;
__decorateClass([
  property6({ type: Boolean, reflect: true })
], LogtoModal.prototype, "open", 2);
__decorateClass([
  property6()
], LogtoModal.prototype, "onClose", 2);
__decorateClass([
  provide({ context: ModalContext })
], LogtoModal.prototype, "context", 2);
LogtoModal = __decorateClass([
  customElement10(tagName10)
], LogtoModal);

// src/components/logto-text-input.ts
import { LitElement as LitElement11, css as css12, html as html11 } from "lit";
import { customElement as customElement11, property as property7, queryAssignedElements } from "lit/decorators.js";
var tagName11 = "logto-text-input";
var LogtoTextInput = class extends LitElement11 {
  constructor() {
    super(...arguments);
    this.disabled = false;
    this.readonly = false;
  }
  render() {
    return html11`<slot @slotchange=${this.handleSlotChange}></slot>`;
  }
  handleSlotChange() {
    if (this.slotInputs[0] && this.slotInputs.length === 1) {
      this.disabled = this.slotInputs[0].disabled;
      this.readonly = this.slotInputs[0].readOnly;
    }
  }
};
LogtoTextInput.tagName = tagName11;
LogtoTextInput.styles = css12`
    :host {
      display: flex;
      position: relative;
      border-radius: ${unit(1.5)};
      border: 1px solid ${vars.colorBorder};
      outline: 3px solid transparent;
      transition-property: outline, border;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      height: 36px;
      background: ${vars.colorLayer1};
      font: ${vars.fontBody2};
      padding: 0 ${unit(3)};
    }

    :host(:focus-within) {
      border-color: ${vars.colorPrimary};
      outline-color: ${vars.colorFocusedVariant};
    }

    :host([disabled]) {
      background: ${vars.colorDisabledBackground};
      color: ${vars.colorTextSecondary};
      border-color: ${vars.colorBorder};
      cursor: not-allowed;
    }

    :host([readonly]) {
      background: ${vars.colorLayer2};
    }

    :host([readonly]:focus-within) {
      border-color: ${vars.colorBorder};
      outline-color: transparent;
    }

    ::slotted(input) {
      all: unset;
      flex: 1;
      color: ${vars.colorTextPrimary};
    }

    ::slotted(input::placeholder) {
      color: ${vars.colorPlaceholder};
    }

    ::slotted(input:webkit-autofill) {
      box-shadow: 0 0 0 ${unit(6)} ${vars.colorLayer1} inset;
      -webkit-text-fill-color: ${vars.colorTextPrimary};
      caret-color: ${vars.colorTextPrimary};
    }
  `;
__decorateClass([
  property7({ type: Boolean, reflect: true })
], LogtoTextInput.prototype, "disabled", 2);
__decorateClass([
  property7({ type: Boolean, reflect: true })
], LogtoTextInput.prototype, "readonly", 2);
__decorateClass([
  queryAssignedElements({ selector: "input" })
], LogtoTextInput.prototype, "slotInputs", 2);
LogtoTextInput = __decorateClass([
  customElement11(tagName11)
], LogtoTextInput);

// src/elements/logto-profile-card.ts
import { consume as consume2 } from "@lit/context";
import { localized as localized5, msg as msg5 } from "@lit/localize";
import { cond as cond3 } from "@silverhand/essentials";
import { LitElement as LitElement13, css as css13, html as html13 } from "lit";
import { customElement as customElement13, state } from "lit/decorators.js";

// src/providers/logto-user-provider.ts
import { createContext as createContext2, provide as provide2 } from "@lit/context";
import { noop as noop4 } from "@silverhand/essentials";
import { LitElement as LitElement12, html as html12 } from "lit";
import { customElement as customElement12, property as property8 } from "lit/decorators.js";

// src/utils/api.ts
import originalKy from "ky";
var LogtoAccountApi = class {
  constructor(init) {
    this.ky = "create" in init ? init : originalKy.create(init);
  }
  async getUser() {
    return this.ky("me").json();
  }
  async updateUser(user) {
    return this.ky.patch("me", { json: user }).json();
  }
};

// src/providers/logto-user-provider.ts
var UserContext = createContext2("user-context");
var userContext = Object.freeze({
  updateUser: noop4
});
var tagName12 = "logto-user-provider";
var LogtoUserProvider = class extends LitElement12 {
  constructor() {
    super(...arguments);
    this.context = userContext;
  }
  render() {
    return html12`<slot></slot>`;
  }
  updateContext(context) {
    this.context = Object.freeze({ ...this.context, ...context });
  }
  async handlePropertiesChange(changedProperties) {
    if (changedProperties.has("api")) {
      const api = this.api instanceof LogtoAccountApi ? this.api : new LogtoAccountApi(this.api);
      this.updateContext({
        updateUser: async (user) => {
          const updated = await api.updateUser(user);
          this.updateContext({ user: updated });
        },
        user: await api.getUser()
      });
    }
  }
  firstUpdated(changedProperties) {
    void this.handlePropertiesChange(changedProperties);
  }
};
LogtoUserProvider.tagName = tagName12;
__decorateClass([
  provide2({ context: UserContext })
], LogtoUserProvider.prototype, "context", 2);
__decorateClass([
  property8({ type: Object })
], LogtoUserProvider.prototype, "api", 2);
LogtoUserProvider = __decorateClass([
  customElement12(tagName12)
], LogtoUserProvider);

// src/elements/logto-profile-card.ts
var tagName13 = "logto-profile-card";
var LogtoProfileCard = class extends LitElement13 {
  constructor() {
    super(...arguments);
    this.updateNameOpened = false;
    this.name = "";
  }
  render() {
    const user = this.userContext?.user;
    if (!user) {
      return html13`<logto-form-card heading=${msg5("Profile", { id: "account.profile.title" })}>
        <p class="dev">⚠️ ${msg5("No user provided.", { id: "account.profile.no-user" })}</p>
      </logto-form-card>`;
    }
    return html13`
      <logto-form-card heading=${msg5("Profile", { id: "account.profile.title" })}>
        <p class="dev">🚧 This section is a dev feature that is still working in progress.</p>
        <logto-card-section
          heading=${msg5("Personal information", { id: "account.profile.personal-info.title" })}
        >
          <logto-list>
            <logto-list-row>
              <div slot="title">
                ${msg5("Avatar", {
      id: "account.profile.personal-info.avatar",
      desc: "The avatar of the user."
    })}
              </div>
              ${cond3(
      user.avatar && html13`<div slot="content">
                    <logto-avatar size="large" src=${user.avatar}></logto-avatar>
                  </div>`
    )}
              <div slot="actions">
                <logto-button type="text" size="small">
                  ${msg5("Change", { id: "general.change" })}
                </logto-button>
              </div>
            </logto-list-row>
            <logto-list-row>
              <div slot="title">
                ${msg5("Name", {
      id: "account.profile.personal-info.name",
      desc: "The name of the user."
    })}
              </div>
              ${cond3(user.name && html13`<div slot="content">${user.name}</div>`)}
              <div slot="actions">
                <logto-button
                  type="text"
                  size="small"
                  @click=${() => {
      this.updateNameOpened = true;
      this.name = user.name ?? "";
    }}
                >
                  ${msg5("Update", { id: "general.update" })}
                </logto-button>
              </div>
            </logto-list-row>
          </logto-list>
        </logto-card-section>
      </logto-form-card>
      <logto-modal
        ?open=${this.updateNameOpened}
        .onClose=${() => {
      this.updateNameOpened = false;
    }}
      >
        <logto-modal-layout
          heading=${msg5("Update name", {
      id: "account.profile.personal-info.update-name"
    })}
        >
          <logto-text-input>
            <input
              placeholder=${msg5("Person Doe", {
      id: "account.profile.personal-info.name-placeholder",
      desc: "The placeholder for the name input field."
    })}
              .value=${this.name}
              @input=${(event) => {
      this.name = event.target.value;
    }}
            />
          </logto-text-input>
          <logto-button
            slot="footer"
            size="large"
            type="primary"
            @click=${async () => {
      await this.userContext?.updateUser({ name: this.name });
      this.updateNameOpened = false;
    }}
          >
            ${msg5("Save", { id: "general.save" })}
          </logto-button>
        </logto-modal-layout>
      </logto-modal>
    `;
  }
};
LogtoProfileCard.tagName = tagName13;
LogtoProfileCard.styles = css13`
    p.dev {
      color: ${vars.colorTextSecondary};
    }
  `;
__decorateClass([
  consume2({ context: UserContext, subscribe: true })
], LogtoProfileCard.prototype, "userContext", 2);
__decorateClass([
  state()
], LogtoProfileCard.prototype, "updateNameOpened", 2);
__decorateClass([
  state()
], LogtoProfileCard.prototype, "name", 2);
LogtoProfileCard = __decorateClass([
  customElement13(tagName13),
  localized5()
], LogtoProfileCard);

// src/providers/logto-theme-provider.ts
import { LitElement as LitElement14, css as css14, html as html14 } from "lit";
import { customElement as customElement14, property as property9 } from "lit/decorators.js";
var tagName14 = "logto-theme-provider";
var LogtoThemeProvider = class extends LitElement14 {
  constructor() {
    super(...arguments);
    this.theme = "default";
  }
  render() {
    return html14`<slot></slot>`;
  }
};
LogtoThemeProvider.tagName = tagName14;
LogtoThemeProvider.styles = css14`
    ${toLitCss(defaultTheme)}
    ${toLitCss(darkTheme, "dark")}
  `;
__decorateClass([
  property9({ reflect: true })
], LogtoThemeProvider.prototype, "theme", 2);
LogtoThemeProvider = __decorateClass([
  customElement14(tagName14)
], LogtoThemeProvider);

// src/utils/locale.ts
import { configureLocalization } from "@lit/localize";

// src/generated/locale-codes.ts
var sourceLocale = `en`;
var targetLocales = [
  `ar-AR`,
  `de`,
  `es`,
  `fr`,
  `it`,
  `ja`,
  `ko`,
  `pl-PL`,
  `pt-BR`,
  `pt-PT`,
  `ru`,
  `tr-TR`,
  `zh-CN`,
  `zh-HK`,
  `zh-TW`
];

// src/utils/locale.ts
var initLocalization = () => configureLocalization({
  sourceLocale,
  targetLocales,
  loadLocale: async (locale) => import(`/locales/${locale}.js`)
});

export {
  LogtoAvatar,
  LogtoButton,
  LogtoCardSection,
  LogtoCard,
  LogtoFormCard,
  LogtoIconButton,
  LogtoListRow,
  LogtoList,
  LogtoModalLayout,
  LogtoModal,
  LogtoTextInput,
  LogtoAccountApi,
  UserContext,
  userContext,
  LogtoUserProvider,
  LogtoProfileCard,
  LogtoThemeProvider,
  initLocalization
};
